.mobile-application {
  &__heading {
    padding-top: 2rem;
    font-size: 2.4rem;
    @media screen and (max-width: 1200px) {
      text-align: center;
    }
  }

  &__type {
    font-size: 2.2rem;
    font-weight: bold;
    padding-bottom: 1rem;
    color: black;
  }

  &__card-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 3rem 0;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      // align-content: center;
      // justify-content: center;
    }
  }

  &__card {
    padding: 2rem;
    flex: 1 0 20rem;
    border-radius: 1rem;
    max-width: 40rem;
    box-shadow: var(--box-shadow-cardlink);
    margin-bottom: 2rem;
    &:not(:last-of-type) {
      margin-right: 3rem;
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      margin: 2rem 0;
      &:not(:last-of-type) {
        margin-right: 0;
      }
      max-width: 100%;
    }
  }

  &__list {
    color: #747474;
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 2rem;
    margin-bottom: 2rem;

    & li {
      list-style-type: disc;
      display: list-item;
    }
  }

  &__icon {
    &-android {
      display: inline-flex;
      align-items: center;
    }

    &-ios {
      display: inline-flex;
      align-items: center;
    }

    &-huawei {
      display: inline-flex;
      align-items: center;
    }

    &-android::after {
      content: url("../img/gplayicon.png");
      padding-left: 0.6rem;
    }

    &-ios::after {
      content: url("../img/appstoreicon.png");
      padding-left: 0.6rem;
    }

    &-huawei::after {
      content: url("../img/huawei-app-icon.png");
      padding-left: 0.6rem;
    }
  }
}

@mixin pseudo-element($element) {
  &:#{$element},
  &::#{$element} {
    @content;
  }
}
