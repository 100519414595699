.hero {
  position: relative;
  //padding: 5rem 40% 5rem 6rem;
  padding: 4% 40% 12% 6rem;
  background-image: url(../../img/herohead.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  z-index: 5;
  // margin-bottom: -5%;

  &__content {
    padding-bottom: 3rem;
  }

  @media only screen and (max-width: 1200px) {
    padding: 5rem 6rem 5rem 6rem;
    background-size: auto;
  }

  @media only screen and (max-width: 960px) {
    padding: 5rem 6rem 5rem 6rem;
    background-size: auto;
  }

  @media only screen and (max-width: 600px) {
    padding: 5rem 6rem 5rem 6rem;
    background-size: cover;
  }

  &__title {
    font-size: 5.4rem;
    line-height: 1.1;
    font-weight: 400;
    margin: -0.5rem 0px 6rem 0px;
  }

  &__subtitle {
    font-size: 2.8rem;
    font-weight: 300;
  }

  &__text {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
}
