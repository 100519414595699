.container {
  padding: 5rem 6rem 5rem 6rem;
}

.randompartner-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.partner {
  display: flex;
  flex: 0 1 25rem;
  display: flex;
  min-height: 25rem;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // box-shadow: 0px 0.8rem 2rem 0.8rem rgba(18, 19, 20, 0.1);
  box-shadow: rgba(5, 66, 65, 0.15) 0px 8px 20px 0px;
  border-radius: 0.6rem;
  padding: 2rem;
  margin: 2rem;

  @media screen and (max-width: 625px) {
    flex: 1 1 30rem;
  }
}

.loading {
  padding-top: 10%;
  font-size: 2.5rem;
  font-weight: 100;
}
