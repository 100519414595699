.hero-card {
  padding: 0rem 6rem 5rem 6rem;
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: column;

  &__content {
    display: flex;
    flex-flow: row wrap;
    padding: 3rem 0;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }

    & > * {
      flex: 1 1 0;
    }
  }
  &__image {
    flex: 0 1 auto;
    img {
      max-width: 100%;
      padding-bottom: 2rem;
    }
  }

  &__text {
    min-width: 40%;
  }
}

// @media screen and (max-width: 960px) {
//   .column {
//     flex: 80%;
//     flex-direction: column;
//     justify-content: center;
//     align-content: center;
//     padding: 0;
//   }
// }
