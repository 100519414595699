$color-white: #ffffff;
$color-black: #252a32;
$color-light: #f1f5f8;
$color-red: #d32f2f;
$color-blue: #017fa7;

$box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /**/
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  /**/
}

html {
  font-size: 62.5%; /* 10px/16px */

  @media only screen and (max-width: 960px) {
    font-size: 55%;
  }
}

#root {
  position: relative;
  display: flex;
  overflow: hidden;
  // height: 100vh;
  min-height: 100vh;
  --title-color: #333;
  --text-color-primary: #036493;
  --accent-color: #036695;
  --color-primary: #036493;
  --color-secondary: #036493;
  --box-shadow-cardlink: 0px 8px 20px 0px rgba(5, 66, 65, 0.1);
  --background-gradient: linear-gradient(to right, #edf2f6, #d7e6ed);
}

body {
  font-family: -apple-system, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  line-height: 1.5;
  font-size: 1.6rem;
}

a,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  &:active {
    position: relative;
    top: 1px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

.title-main {
  font-size: 4.6rem;
  line-height: 1;
}

.title-padding {
  padding-bottom: 3rem;
}

.text-padding {
  padding-bottom: 2rem;
}

p {
  font-size: 1.6rem;
  color: #747474;
  font-weight: 300;
  line-height: 1.5;
}

.container {
  padding: 5rem 7.5rem 5rem 7.5rem;
  position: relative;
  padding-bottom: 2.5rem; // Footer height

  @media only screen and (max-width: 625px) {
    padding: 3rem;
  }
}

// @media only screen and (max-width: 960px) {
//   #root {
//     flex-direction: column;
//   }
// }
