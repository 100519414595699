@import "./index.scss";

.contact-background {
  background-image: url(./img/kontakt-1024x395.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  height: fit-content;
}

.contact {
  &__info {
    font-size: 2rem;
    font-weight: 300;
    padding-right: 4em;
  }

  &__phone a:link,
  &__phone a:hover {
    font-size: 2.5rem;
    color: #042561;
    font-weight: 600;
  }

  p {
    font-size: 1.4rem;
  }
}

.contact-form {
  min-width: 50rem;
  max-width: 50%;

  @media only screen and (max-width: 960px) {
    max-width: 100%;
    min-width: 100%;
  }

  .form {
    width: 100%;
    height: auto;
    margin: 3.2rem 0;

    &__disclaimer {
      font-size: 14px;
      color: #747474;
      padding-bottom: 0.5rem;
    }

    &-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.6rem;

      .input-field {
        font-family: inherit;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: inherit;
        width: 100%;
        height: auto;
        padding: 1.2rem 2rem;
        border: none;
        outline: none;
        border-radius: 0.5rem;
        color: $color-black;
        background: $color-light;
      }

      .input-submit {
        font-family: inherit;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: inherit;
        cursor: pointer;
        padding: 1rem 3.2rem;
        border: none;
        outline: none;
        border-radius: 0.5rem;
        text-align: center;
        color: $color-white;
        background: $color-blue;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:hover {
          background: darken($color-blue, 5);
        }

        &:active {
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

.error {
  padding: 1rem;
  font-size: 1.4rem;
  color: #da0812;
}

.success {
  padding: 1rem;
  font-size: 1.4rem;
  color: #036695;
}
