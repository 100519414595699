.nurse {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-image: linear-gradient(to bottom, #fff, #eef2f7);

  @media screen and (max-width: 1200px) {
    display: block;
  }

  &__image {
    flex: 50%;
  }

  &__image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-content {
    flex: 1 1 40rem;
    padding: 3rem 6rem 3rem 6rem;
  }

  &__list {
    color: #747474;
    font-weight: 300;
    margin-left: 2rem;
  }

  &__list-item {
    list-style-type: disc;
  }
}
