@import "./index.scss";

.background-image {
  background-image: var(--background-gradient);
  min-height: 70vh;
}

.profile {
  display: flex;
  flex-flow: row wrap;

  &__card {
    min-width: 40rem;
    max-width: 45rem;
    height: fit-content;
    padding-bottom: 2rem;
    margin-right: 4rem;

    @media only screen and (max-width: 960px) {
      margin-right: 0rem;
    }

    @media only screen and (max-width: 500px) {
      min-width: 80%;
    }
  }

  @media only screen and (max-width: 960px) {
    flex-flow: column wrap;
    display: block;
  }

  & > * {
    flex: 0 0 50%;
  }

  &__user {
    margin-top: 3rem;
    p {
      margin: 2rem 0;
      font-size: 2.2rem;
    }
  }

  &__username {
    font-size: 3rem;
    color: var(--accent-color);
  }

  &__password {
    .form {
      width: 100%;
      min-width: 40rem;
      max-width: 40rem;
      height: auto;
      margin: 3.2rem 0;

      @media only screen and (max-width: 960px) {
        max-width: 40rem;
        min-width: 100%;
      }

      &-group {
        @media only screen and (max-width: 960px) {
          max-width: 40rem;
        }

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.6rem;

        .input-field {
          font-family: inherit;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: inherit;
          width: 100%;
          height: auto;
          padding: 1.2rem 2rem;
          border: none;
          outline: none;
          border-radius: 0.5rem;
          color: $color-black;
          background: $color-light;
        }

        .input-submit {
          font-family: inherit;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: inherit;
          cursor: pointer;
          padding: 1rem 3.2rem;
          border: none;
          outline: none;
          border-radius: 0.5rem;
          text-align: center;
          color: $color-white;
          background: $color-blue;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &:hover {
            background: darken($color-blue, 5);
          }

          &:active {
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}

.error {
  padding: 1rem;
  font-size: 1.4rem;
  color: #da0812;
}

.success {
  padding: 1rem;
  font-size: 1.4rem;
  color: #036695;
}
