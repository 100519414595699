.partner-image {
  max-width: 20rem;
  min-height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-image img {
  width: 100%;
  padding: 0.2rem;
}

.partner-name {
  font-size: 2.2rem;
  color: var(--text-color-primary);
  text-align: center;
}

.partner-description {
  font-size: 1.4rem;
  text-align: center;
  color: #747474;
}
