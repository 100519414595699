.partner-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.partner-header-description {
  padding: 1rem 0 2rem 0;
}

.partner-header-image {
  max-width: 20rem;
}

.tabs {
  margin-top: 3rem;
}

.tab {
  display: inline-block;
  padding: 15px;
  background-color: #017fa7;
  border-radius: 5px 5px 0 0;
  color: white;

  &:not(:last-child) {
    margin-right: 3px;
  }

  @media only screen and (max-width: 625px) {
    padding: 10px;
    display: block;
    border-radius: 0;
    &:not(:last-child) {
      margin-right: 0px;
    }
  }
}

.tab-content {
  border: 1px solid#03497E;
  padding: 2rem;
  min-height: 60vh;
}

.active-tab {
  background-color: #03497e;
  border-bottom: 3px solid #03497e;
}

.wide-image {
  max-width: 100%;
}
