nav {
  flex: 0 0 auto;
  // background-image: linear-gradient(to bottom, #042b66, #036493 50%, #0098ba);
  background: linear-gradient(
    to bottom,
    #042b66,
    #036493 50vh,
    #0098ba 100vh,
    #0098ba 100%
  );
  color: #fff;
  display: flex;
  flex-direction: column;
  // height: auto;
  // height: fit-content;
  // min-height: 100vh;

  &::after {
    background-color: #0098ba;
    height: max-content;
  }
}

.nav-header {
  margin-bottom: 2rem;
}

.nav-items li {
  font-weight: 300;
  font-size: 1.8rem;
  text-align: center;
  padding: 1rem;
  list-style-type: none;
  text-decoration: none;
}

.nav-items a {
  text-decoration: none;
}

.nav-items a:visited,
.nav-items a:link {
  color: #fff;
}

.nav-items a li:hover {
  font-weight: 600;
}

.nav-items .active-link li:hover {
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.nav-items .active-link li {
  font-weight: 900;
  text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.3);
}

.nav-items .active-link li:hover {
  text-decoration: none;
  color: #fff;
}

.logo {
  width: 100%;
  padding: 1rem;
}

.hero-info {
  font-size: 2rem;
  display: flex;
  height: 25vh;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
}

.menu-icon {
  display: none;
}

@media only screen and (max-width: 960px) {
  nav ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  nav {
    position: fixed;
    width: 100%;
    display: block;
    justify-content: flex-start;
    padding-bottom: 20px;
    min-width: 275px;
    padding: 10px 30px;
    z-index: 10;
    transition: height 0.2s;
  }

  .nav-active {
    height: 100vh;
    overflow: auto;
  }

  .nav-closed {
    position: fixed;
    height: 100px;
    overflow: hidden;
  }

  .nav-items {
    z-index: 10;
  }

  .logo {
    box-sizing: content-box;
    width: 80px;
  }

  .toggle-nav {
    display: block;
    padding-right: 20px;
  }

  .mobile-menu {
    order: 0;
    cursor: pointer;
    transition: all 1s;
  }

  .nav-header a {
    order: 1;
  }

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 30px;
  }

  .menu-icon {
    display: block;
    color: #fff;
    width: 35px;
    height: 35px;
    user-select: none;
  }
}

.sidebar__contact {
  padding-top: 2rem;
  font-size: 1.8rem;
  text-align: center;
}

.sidebar__contact-group {
  padding-top: 2rem;
}

.sidebar__contact-title {
  color: #fff;
  font-weight: 400;
}

.sidebar__contact-content {
  color: #0f3455;
  font-weight: 600;

  a:link,
  a:visited {
    color: inherit;
    font-weight: 600;
  }
}
