@import "./index.scss";

//Components
.wrapper {
  max-width: 44rem;
  width: 100%;
  margin: 0 auto;
  padding: 3.2rem 2.4rem;
}

.card {
  padding: 2.4rem 3.2rem;
  border: none;
  outline: none;
  border-radius: 0.8rem;
  color: $color-black;
  background: $color-white;
  box-shadow: $box-shadow;
}

.linktext {
  padding-left: 1.5rem;
  font-family: inherit;
  font-size: 1.6rem;
  line-height: inherit;
  color: $color-blue;
  text-rendering: optimizeLegibility;

  &:hover {
    text-decoration: underline;
  }
}

.title {
  font-family: inherit;
  line-height: inherit;
  text-rendering: optimizeLegibility;

  &-large {
    font-size: 3rem;
    font-weight: 400;
    color: $color-black;
  }

  &-subs {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: inherit;
  }
}

// Main
.main {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--background-gradient);

  @media only screen and (max-width: 960px) {
    display: block;
  }

  .wrapper {
    .form {
      width: 100%;
      height: auto;
      margin: 3.2rem 0;

      &-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.6rem;

        .input-field {
          font-family: inherit;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: inherit;
          width: 100%;
          height: auto;
          padding: 1.2rem 2rem;
          border: none;
          outline: none;
          border-radius: 0.5rem;
          color: $color-black;
          background: $color-light;
        }

        .input-submit {
          font-family: inherit;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: inherit;
          cursor: pointer;
          padding: 1rem 3.2rem;
          border: none;
          outline: none;
          border-radius: 0.5rem;
          text-align: center;
          color: $color-white;
          background: $color-blue;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &:hover {
            background: darken($color-blue, 5);
          }

          &:active {
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}

.error {
  padding: 1rem;
  font-size: 1.4rem;
  color: #da0812;
}

.success {
  padding: 1rem;
  font-size: 1.4rem;
  color: #036695;
}
