.footer {
  margin-top: auto;
  margin-left: 7.5rem;
  margin-right: 7.5rem;
  padding: 3rem 0;

  @media only screen and (max-width: 625px) {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  &__main {
    margin-top: 2rem;
    border-top: 0.1rem solid #ddd;
    padding-top: 4rem;
    display: flex;
    flex-flow: row wrap;
  }

  &__sponsors {
    margin-right: 6rem;
    flex: 0 1 auto;

    @media only screen and (max-width: 660px) {
      margin-right: 0;
    }
  }

  &__sponsors {
    display: block;
    .sponsor-link {
      img {
        padding-bottom: 1rem;
        padding-right: 1em;
      }
    }
    padding-bottom: 3rem;
  }

  &__disclaimer {
    color: #9c9c9c;
    padding: 2rem 0;
    font-size: 1.2rem;
  }

  &__contact {
    padding-bottom: 2rem;

    &-title {
      color: #747474;
      font-size: 2.2rem;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 1.4rem;
    }

    &-details {
      padding-bottom: 1rem;

      a:link {
        color: #042561;
      }
      a:hover {
        color: #19568e;
      }
    }
  }
}
