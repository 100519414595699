.scrollTop {
  cursor: pointer;
  animation: fadeIn 0.2s;
  transition: opacity 0.5s;
  opacity: 0.2;
  color: #042b66;
}

.arrow-container {
  position: fixed;
  bottom: 40px;
  right: 15px;
  z-index: 1000;
}

.arrow-container:hover .scrollTop {
  transform: translateY(-3px);
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
