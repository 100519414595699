.card-info-group {
  padding: 3rem 7.5rem 2rem 7.5rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  background-color: #fff;
  top: -10rem;
  position: relative;
}

.card-block {
  padding: 1rem 2rem 3rem 2rem;
  text-align: center;
  min-height: 25rem;
  max-height: fit-content;
}

.card-block-detail {
  color: #0087cc;
  font-weight: 400;
}

@media only screen and (max-width: 1300px) {
  .card-info-group {
    flex-direction: column;
    align-items: center;
  }
  .card-info {
    flex: 1;
    width: 80%;
  }
}

@media only screen and (max-width: 960px) {
  .card-info-group {
    flex-direction: column;
    align-items: center;
  }
  .card-info {
    flex: 1;
    width: 80%;
  }
}

.card-info {
  z-index: 6;
  display: flex; /* new */
  flex-direction: column; /* new */
  background-color: #fff;
  margin: 2rem;
  flex: 1;
  box-shadow: var(--box-shadow-cardlink);
  border-radius: 0.8rem;
  overflow: hidden;
  max-width: 50rem;
}

.card-info img {
  width: 100%;
  min-height: 25rem;
  max-height: 25rem;
  object-fit: cover;
}

.card-title {
  padding: 2.5rem 1rem;
  font-size: 2.2rem;
  font-weight: 400;
  color: var(--title-color);
  text-align: center;
}
