.partner-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.partner {
  display: flex;
  flex: 0 1 25rem;
  display: flex;
  min-height: 25rem;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // box-shadow: 0px 0.8rem 2rem 0.8rem rgba(18, 19, 20, 0.1);
  box-shadow: rgba(5, 66, 65, 0.15) 0px 8px 20px 0px;
  border-radius: 0.6rem;
  padding: 2rem;
  margin: 2rem;

  @media screen and (max-width: 625px) {
    flex: 1 1 30rem;
  }

  &__header {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    // align-items: center;
    padding-bottom: 3rem;
  }

  &__search {
    transition: all 0.3s ease-in-out;
    margin-top: 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 1rem 2rem;
    border: 1px solid #dddddd;
    outline: none;
    border-radius: 0.5rem;

    &:focus {
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
      border: 1px solid rgba(81, 203, 238, 1);
    }
  }
}

.loading {
  padding-top: 10%;
  font-size: 2.5rem;
  font-weight: 100;
  width: 100%;
  text-align: center;
}

.partner:link,
.partner:visited {
  color: var(--text-color-primary);
}
