/* .wrapper {
  display: flex;
  height: 100vh;
} */

.main-content {
  flex: 1 1 auto;
  overflow: auto;
  min-height: 80vh;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 960px) {
    margin-top: 100px;
  }
}
