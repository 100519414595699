.category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
}

.category {
  display: flex;
  flex: 0 1 30%;
  align-items: center;
  padding: 1rem 2rem;
  margin: 1.5rem;
  box-shadow: 0px 2px 6px 5px rgba(18, 19, 20, 0.1);
  min-width: 30rem;
  border-radius: 0.5rem;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;

  &__header {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    // align-items: center;
    padding-bottom: 3rem;
  }

  &__search {
    transition: all 0.3s ease-in-out;
    margin-top: 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 1rem 2rem;
    border: 1px solid #dddddd;
    outline: none;
    border-radius: 0.5rem;

    &:focus {
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
      border: 1px solid rgba(81, 203, 238, 1);
    }
  }

  &__title {
    font-size: 1.8rem;
  }

  &:link,
  &:visited {
    color: var(--text-color-primary);
  }

  &:hover {
    /* transform: scale(1.01); */
    box-shadow: 0px 0.3rem 0.6rem 0.5rem rgba(18, 19, 20, 0.1);
    transform: translateY(-0.2rem);
  }

  &-color {
    margin-right: 20px;
    height: 20px;
    width: 20px;
    flex: 0 0 auto;
  }
}
