.discount {
  &:not(:first-of-type) {
    padding: 2rem 0;
  }

  &__duration {
    font-size: 1.4rem;
    color: #da0812;
    padding-bottom: 2rem;
  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid #03497e44;
  }

  &__name {
    color: #03497e;
    font-weight: 400;
    padding-bottom: 0.1rem;
  }

  &__text {
    color: #747474;
    padding-bottom: 2rem;
  }

  .discount__leaflet {
    &-image {
      max-width: 25rem;
    }
  }

  &__images {
    padding-bottom: 2rem;
  }
}
