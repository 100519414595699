.location {
  padding-top: 2rem;

  &:first-child {
    padding-top: 0px;
  }

  &:not(:last-child) {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid #03497e44;
  }

  &__name {
    font-weight: 400;
    font-size: 1.8rem;
    padding-bottom: 0.5rem;
    color: #03497e;
  }

  &__data {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;

    &-icon {
      margin-right: 1rem;
    }

    a:link,
    a:visited {
      color: #747474;
      font-weight: 300;
    }
  }

  &__button {
    display: block;
    margin-top: 0.5rem;
    width: 16rem;
    text-align: center;
    padding: 0.2rem 0.3rem;
    background-color: #03497e;
    border-radius: 0.5rem;
    &:link,
    &:visited {
      color: #fff;
    }
  }
}
