.locations {
  display: flex;
  flex-flow: column wrap;
  color: #747474;
}

.locations-map {
  padding: 1rem 0 2rem 0;
}

.locations-button {
  padding: 1rem;
  background-color: #da0812;
  border-radius: 0.5rem;
  &:link,
  &:visited {
    color: #fff;
  }
}
