ul.list {
  color: #747474;
  font-weight: 300;
  list-style: circle;
  list-style-type: circle;
  padding-left: 2em;
}

ul.list li {
  margin-left: 3rem;
  list-style-type: disc;
}

.last-line {
  font-weight: 700;
  font-size: 1.4em;
}

.page {
  &__title {
    font-size: 5.2rem;
    font-weight: 400;
    line-height: 1.1;
  }

  &__subtitle {
    font-size: 2.8rem;
    font-weight: 300;
  }
}

.negative-margin {
  margin-top: -0.5rem;
}

.links-container {
  margin-bottom: 3rem;
}

.download {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  &__link:link,
  &__link:visited {
    color: #19568e;
    display: flex;
    align-items: center;

    &::before {
      content: url("../img/pdf-icon.png");
      padding-right: 2rem;
    }
  }

  &-colored {
    background: linear-gradient(to right, #cddfe6, #fff);
    width: 80%;
    padding: 1rem 3rem;
  }

  .title-padding {
    padding-bottom: 3rem;
  }
}
.email {
  a:link {
    color: #042561;
  }
  a:hover {
    color: #19568e;
  }
}
